import React from "react";
import { useSelector } from "react-redux";

import { cartSelectors } from "../../../cart/_core/cartSelectors";
import { SauceProductQuantity } from "./SauceProductQuantity";
import { WidthRatioImageContainer } from "../../../common/ratioContainers/WidthRatioImageContainer";
import { titleFormatter } from "../../../../utils/titleFormatter";
import {GarnishProductPrice} from "../GarnishProductPrice";
import {useHistory} from "react-router-dom";


export const SauceListItem = ({ sauce }) => {
  const history = useHistory();
  const cartItem = useSelector(state => cartSelectors.cartItem(state, sauce));
  const freeSauceCount = useSelector(cartSelectors.additionalData).freeItemsRemaining.get('sauce');
  const productInCart = cartItem.count ? 'incart' : 'none';
  const open = () => history.push(`/sauce-detail/${sauce.id}`);

  return (
    <li className="secondary-goods__list-item" data-item={productInCart}>
      <div className="secondary-goods__item">
        <h3 className="secondary-goods__title">
          <span>{titleFormatter(sauce.title)}</span>
        </h3>

        <GarnishProductPrice garnish={sauce} freeGarnishesCount={freeSauceCount}/>

        <WidthRatioImageContainer
            onClick={open}
            ratio={600 / 476}
            publicImages={sauce.publicImages}
            type="main"
            className="secondary-goods__picture"/>

        <SauceProductQuantity product={sauce}/>
        <article className="main-goods__chars">
          <p>{sauce.get('announce')}</p>
        </article>
      </div>
    </li>
  );
};
