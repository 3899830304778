import {OrderedMap} from "immutable";

import {__, toBool} from "../../../utils/utils";
import {GarnishModel} from "../../../models/Products/Garnish/GarnishModel";
import {SauceModel} from "../../../models/Products/Garnish/SauceModel";
import {SticksModel} from "../../../models/Products/Garnish/SticksModel";
import {PRODUCT_TYPES} from "../productBaseConstants";


export const garnishCreator = data => new GarnishModel({
	id: __(data.id),
	announce: __(data.anonce),
	position: __(data.position),
	calories: parseInt(data.calories),
	carbohydrates: parseInt(data.carbohydrates),
	fats: parseInt(data.fats),
	proteins: parseInt(data.proteins),
	price: __(data.price) / 10000,
	publicImages: data.public_images,
	title: __(data.title),
	amount: __(data.amount),

	isOnTrade: toBool(data.is_on_trade),
	isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
});

export const sauceCreator = data => new SauceModel({
	id: __(data.id),
	announce: __(data.anonce),
	position: __(data.position),
	calories: parseInt(data.calories),
	carbohydrates: parseInt(data.carbohydrates),
	fats: parseInt(data.fats),
	proteins: parseInt(data.proteins),
	price: __(data.price) / 10000,
	publicImages: data.public_images,
	title: __(data.title),
	amount: __(data.amount),

	isOnTrade: toBool(data.is_on_trade),
	isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
});

export const stickCreator = data => new SticksModel({
	id: __(data.id),
	announce: __(data.anonce),
	position: __(data.position),
	price: __(data.price) / 10000,
	publicImages: data.public_images,
	title: __(data.title),
	amount: __(data.amount),

	isOnTrade: toBool(data.is_on_trade),
	isSuitableForCurrentCart: toBool(data.is_suitable_for_current_cart),
});

const createGarnishSubtypeFilter = (data, subtype, creator) => OrderedMap().withMutations(map => {
	Object.entries(data)
		.filter(([id, item]) => item.garnish_type === subtype)
		.forEach(([id, item]) => {
			map.set(id, creator(item))
		})
});

export const garnishesProcess = (data) => {
	return {
		[PRODUCT_TYPES.garnish.subtypes.garnish.id]: createGarnishSubtypeFilter(data, PRODUCT_TYPES.garnish.subtypes.garnish.id, garnishCreator),
		[PRODUCT_TYPES.garnish.subtypes.sauce.id]: createGarnishSubtypeFilter(data, PRODUCT_TYPES.garnish.subtypes.sauce.id, sauceCreator),
		[PRODUCT_TYPES.garnish.subtypes.stick.id]: createGarnishSubtypeFilter(data, PRODUCT_TYPES.garnish.subtypes.stick.id, stickCreator),
	}
};
